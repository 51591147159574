import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src2997050974/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "static",
        "static": true
      }}>{`import { ContentEditable } from '@kineticdata/react';

initialState = {
  htmlValue: 'default value',
};

const updateOutput = (_e, value) => {
  setState({ htmlValue: value });
};
const handleHotKeys = e => {
  if (e.nativeEvent.keyCode === 13 && !e.nativeEvent.shiftKey) {
    alert(\`Checking output: \${state.htmlValue}\`);
    e.preventDefault();
    setState({ htmlValue: 'default value' });
  }
};

<div>
  <p>Output: {state.htmlValue}</p>
  <div style={{ border: '1px solid' }}>
    <ContentEditable
      contentEditable="plaintext-only"
      html={state.htmlValue}
      onChange={updateOutput}
      onKeyPress={handleHotKeys}
    />
  </div>
</div>;
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      